.border-send-email {
    margin-top: 1rem;
    border-top: 1px solid #e8e8e8;
}
.date-send-email {
    margin-top: 1rem;

    .created-at {
        font-size: 14px;
        font-weight: 700;
        margin-top: 3rem;
    }
    .date {
        font-size: 14px;
        color: #7b7575;
    }
}
